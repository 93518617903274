import React, { useState } from 'react';
import { Editor, EditorState, RichUtils, ContentState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useTranslation } from 'react-i18next';

function Step3({ nextStep, prevStep, values, setFormData }) {
  const { t } = useTranslation();

  // 如果有之前填寫的 description，使用它來初始化 EditorState，否則使用空的 EditorState
  const [editorState, setEditorState] = useState(
    values.description 
      ? EditorState.createWithContent(ContentState.createFromText(values.description))
      : EditorState.createEmpty()
  );

  // 處理文字編輯器內容變更
  const handleDescriptionChange = (editorState) => {
    setEditorState(editorState);
    const content = editorState.getCurrentContent().getPlainText(); // 將編輯器內容轉換為純文本
    setFormData((prevData) => ({ ...prevData, description: content })); // 將內容存儲在 formData 中
  };

  // 處理格式化
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  // 處理檔案變更
  const handleFileChange = (index, file) => {
    const updatedFiles = [...values.evidenceFiles];
    updatedFiles[index] = file;
    setFormData((prevData) => ({ ...prevData, evidenceFiles: updatedFiles }));
  };
  

  // 添加新檔案
  const handleAddFile = () => {
    setFormData((prevData) => {
      if (prevData.evidenceFiles.length < 10) {
        return {
          ...prevData,
          evidenceFiles: [...prevData.evidenceFiles, null],
        };
      }
      return prevData; // 如果已經有十個檔案，則不做任何改變
    });
  };

  // 移除檔案
  const handleRemoveFile = (index) => {
    const updatedFiles = values.evidenceFiles.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, evidenceFiles: updatedFiles }));
  };

  // 繼續下一步
  const continueStep = (e) => {
    e.preventDefault();
    if (!values.description) {
      alert(t('pleaseCompleteFields')); // 驗證必填欄位
    } else {
      nextStep();
    }
  };

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-white">
      {/* 案件描述區塊 */}
      <h2 className="text-2xl mb-4 font-bold">{t('provideEvidence')}</h2>
      <label className="block text-gray-700 dark:text-gray-400 mb-2">
        {t('description')}<span className="text-red-500">*</span>
      </label>
      <p className="text-gray-600 dark:text-gray-400 mb-4">
        {t('descriptionPlaceholder')}
      </p>

      {/* 文字編輯器 */}
      <div
        className="mb-6 p-2 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-800 dark:text-white rounded"
        style={{ minHeight: '200px' }}
        onClick={() => document.querySelector('.DraftEditor-root').focus()} // 點擊時聚焦
      >
        <Editor
          editorState={editorState}
          onChange={handleDescriptionChange}
          handleKeyCommand={handleKeyCommand}
          placeholder={t('enterDescription')}
        />
      </div>

      {/* 上傳檔案區塊 */}
      <h3 className="text-xl font-semibold mb-2">{t('uploadEvidence')}</h3>
      {values.evidenceFiles.map((file, index) => (
        <div key={index} className="relative mb-4">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(index, e.target.files[0])}
            className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
          />
          {/* 顯示已選擇的檔案名稱 */}
          {file && (
            <div className="mt-2 flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg shadow-md">
              <p className="text-sm text-gray-800 dark:text-gray-200 mr-4 truncate">
                <strong>{t('selectedFile')}:</strong> {file.name}
              </p>
              <button
                type="button"
                onClick={() => handleRemoveFile(index)}
                className="ml-auto text-red-500 hover:text-red-700 focus:outline-none bg-red-100 hover:bg-red-200 px-3 py-1 rounded-lg transition"
              >
                {t('remove')}
              </button>
            </div>
          )}

          {/* 如果有多個檔案，顯示刪除按鈕 */}
          {values.evidenceFiles.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveFile(index)}
              className="absolute right-2 top-2 text-red-500"
            >
              ✖
            </button>
          )}
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddFile}
        className="mt-2 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded"
      >
        ＋ {t('addFile')}
      </button>

      {/* 導航按鈕 */}
      <div className="flex justify-between mt-6">
        <button
          type="button"
          onClick={prevStep}
          className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
        >
          {t('previous')}
        </button>
        <button
          type="button"
          onClick={continueStep}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          {t('next')}
        </button>
      </div>
    </div>
  );
}

export default Step3;
