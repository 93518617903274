// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';  // 導入 useTranslation
import axios from 'axios';
import API_URL from '../config'; // 確保引入正確的 API_URL

function Home() {
  const { t } = useTranslation();  // 使用 useTranslation 來獲取 t 函數
  const [guestReport, setGuestReport] = useState(null); // 用於存儲報案資訊
  const [isExpanded, setIsExpanded] = useState(false); // 控制彈出視窗是否展開

  useEffect(() => {
    // 從 localStorage 中取得 guestId
    const guestId = localStorage.getItem('guestId');
    if (guestId) {
      // 如果有 guestId，從後端獲取報案資訊
      axios.get(`${API_URL}/api/guest_report/${guestId}`)
        .then((res) => {
          setGuestReport(res.data.report);
        })
        .catch((err) => {
          console.error(t('errorFetchingGuestReport'), err);
        });
    }
  }, [t]);

  // 處理點擊泡泡視窗的函數
  const handleTogglePopup = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="relative">
      <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-3xl font-bold mb-6">{t('welcomeToApp')}</h1>
        <p className="text-xl">{t('appDescription')}</p>
      </div>

      {/* 如果有報案資訊，顯示浮動泡泡 */}
      {guestReport && (
        <div
          className={`fixed top-1/2 right-0 transform -translate-y-1/2 bg-white shadow-lg rounded-l-lg overflow-hidden transition-all duration-300 ${
            isExpanded ? 'w-80' : 'w-12'
          }`}
        >
          {/* 點擊區域 */}
          <div onClick={handleTogglePopup} className="cursor-pointer h-full flex flex-col justify-center items-center">
            {/* 展開狀態時，顯示內容 */}
            {isExpanded ? (
              <div className="p-4">
                <h2 className="text-xl font-bold mb-2">{t('guestReportInfo')}</h2>
                <p><strong>{t('guestId')}:</strong> {localStorage.getItem('guestId')}</p>
                <p><strong>{t('incidentType')}:</strong> {guestReport.incidentType}</p>
                <p><strong>{t('description')}:</strong> {guestReport.description}</p>
                {/* 其他需要顯示的報案資訊 */}
              </div>
            ) : (
              // 收起狀態時，顯示一個圖標或簡短提示
              <div className="w-12 h-12 flex items-center justify-center">
                {/* 可以替換為適當的圖標 */}
                <svg className="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
