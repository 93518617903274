// src/pages/BlockchainAcademy.js
import React from 'react';

function BlockchainAcademy() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-6">區塊鏈學院</h1>
      <p>歡迎來到區塊鏈學院，這裡有各種教學與反詐騙文章。</p>
    </div>
  );
}

export default BlockchainAcademy;
