// frontend/src/pages/MyReports.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function MyReports() {
  const { t } = useTranslation();
  const [reports, setReports] = useState([]);
  const [guestId, setGuestId] = useState(''); // 用於關聯報案的 guestId
  const [message, setMessage] = useState('');
  const [guestReport, setGuestReport] = useState(null); // 用於顯示關聯報案的資料

  useEffect(() => {
    // 取得用戶的報案紀錄
    axios.get(`${API_URL}/api/my_reports`, { withCredentials: true })
      .then((res) => {
        setReports(res.data.reports);
      })
      .catch((err) => {
        console.error(t('errorFetchingReports'), err);
      });

      // 如果 localStorage 有 guestId，則取得該 guestId 的報案資料
      const storedGuestId = localStorage.getItem('guestId');
      if (storedGuestId) {
        setGuestId(storedGuestId);
        axios.get(`${API_URL}/api/guest_report/${storedGuestId}`)
          .then((res) => {
            setGuestReport(res.data.report);
          })
          .catch((err) => {
            console.error(t('errorFetchingGuestReport'), err);
          });
      }
  }, [t]);

  const handleExport = (reportId, format) => {
    // 導出報案資料
    window.open(`${API_URL}/api/export_report/${reportId}?format=${format}`, '_blank');
  };

  // 加入收藏
  const handleAddToFavorites = (reportId) => {
    axios.post(`${API_URL}/api/add_to_favorites`, { reportId }, { withCredentials: true })
      .then((res) => {
        alert(t('addedToFavorites'));
      })
      .catch((err) => {
        console.error(t('errorAddingToFavorites'), err);
      });
  };

  // 關聯報案的函數
  const handleLinkReport = () => {
    axios.post(`${API_URL}/api/link_report`, { guestId }, { withCredentials: true })
      .then((res) => {
        setMessage(t('reportLinked'));
        setGuestId(''); // 重置 guestId
        setGuestReport(null);
        localStorage.removeItem('guestId');
      })
      .catch((err) => {
        console.error(t('errorLinkingReport'), err);
        setMessage(t('errorLinkingReport'));
      });
  };

  return (
    <div className="my-reports-container">
      <h2>{t('myReports')}</h2>
      
      {/* 關聯報案的部分 */}
      <div className="link-report-container">
        <h3>{t('linkGuestReport')}</h3>

        {/* 如果存在 guestReport，則顯示該報案的資訊 */}
        {guestReport ? (
          <div className="guest-report-info mb-4 p-4 border border-gray-300 rounded">
            <p>{t('guestId')}: {guestId}</p>
            <p>{t('incidentType')}: {guestReport.incidentType}</p>
            <p>{t('description')}: {guestReport.description}</p>
            {/* 其他需要展示的信息 */}
            <button onClick={handleLinkReport} className="bg-green-500 hover:bg-green-600 text-white p-2 rounded mt-4">
              {t('confirmLinkReport')}
            </button>
          </div>
        ) : (
          <>
            {/* 如果不存在 guestReport，則顯示輸入框和按鈕 */}
            <input
              type="text"
              value={guestId}
              onChange={(e) => setGuestId(e.target.value)}
              placeholder={t('enterGuestId')}
              className="p-2 border border-gray-300 rounded mb-4 w-full"
            />
            <button onClick={handleLinkReport} className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded">
              {t('linkReport')}
            </button>
          </>
        )}
        {message && <p className="mt-4 text-green-500">{message}</p>}
      </div>

      {/* 顯示用戶的報案紀錄 */}
      {reports.map((report) => (
        <div key={report._id} className="report-item">
          <p>{t('reportId')}: {report._id}</p>
          <p>{t('incidentType')}: {report.incidentType}</p>
          <button onClick={() => handleExport(report._id, 'pdf')}>{t('exportAsPDF')}</button>
          <button onClick={() => handleExport(report._id, 'json')}>{t('exportAsJSON')}</button>
          <button onClick={() => handleAddToFavorites(report._id)}>{t('addToFavorites')}</button>
        </div>
      ))}
    </div>
  );
}

export default MyReports;
