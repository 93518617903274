// frontend/src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Result from './pages/Result';
import Report from './pages/Report';
import WalletRisk from './pages/WalletRisk';
import PathAnalysis from './pages/PathAnalysis';
import BlockchainAcademy from './pages/BlockchainAcademy';
import AIRiskPrediction from './pages/AIRiskPrediction';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import MyReports from './pages/MyReports';
import Favorites from './pages/Favorites';
import Navbar from './components/Navbar'; 
import { useTranslation } from 'react-i18next'; // 導入i18n：中英切換
import './i18n';
import Footer from './components/Footer';
import { MoonIcon, SunIcon, GlobeAltIcon } from '@heroicons/react/24/solid';

function App() {
  const { i18n } = useTranslation();
  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log("Language switched to:", lng);
  };

  return (
    <Router>
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
        <Navbar />

        <div className="flex justify-end p-4">
        <button onClick={toggleDarkMode} className="bg-blue-500 text-white p-2 rounded mr-4">
          {darkMode ? <SunIcon className="h-6 w-6" /> : <MoonIcon className="h-6 w-6" />}
        </button>

          {/* 語言切換：如果當前語言是中文，顯示英文按鈕 */}
          {i18n.language === 'zh' && (
            <button onClick={() => changeLanguage('en')} className="bg-green-500 text-white p-2 rounded flex items-center">
              <GlobeAltIcon className="h-5 w-5 mr-1" />
              English
            </button>
          )}

          {/* 語言切換：如果當前語言是英文，顯示中文按鈕 */}
          {i18n.language === 'en' && (
            <button onClick={() => changeLanguage('zh')} className="bg-green-500 text-white p-2 rounded flex items-center">
              <GlobeAltIcon className="h-5 w-5 mr-1" />
              中文
            </button>
          )}
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/wallet-risk" element={<WalletRisk />} />
          <Route path="/result" element={<Result />} />
          <Route path="/report" element={<Report />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/my-reports" element={<MyReports />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/path-analysis" element={<PathAnalysis />} />
          <Route path="/academy" element={<BlockchainAcademy />} />
          <Route path="/ai-prediction" element={<AIRiskPrediction />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
