import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Step1 from './ReportSteps/Step1';
import Step2 from './ReportSteps/Step2';
import Step3 from './ReportSteps/Step3';
import Step4 from './ReportSteps/Step4';
import StepIndicator from './ReportSteps/StepIndicator';
import API_URL from '../config';

function Report() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);

  // 將所有步驟需要的資料放在 formData 中
  const [formData, setFormData] = useState({
    incidentType: '',
    description: '',
    evidenceFiles: [null], // 初始為一個空的檔案
    email: '',
    otherIncidentType: '',
    fullName: '',
    phoneNumber: '',
    country: '',
    city: '',
    // 將步驟2的所有資料放入 formData 內
    scammerAddresses: [
      {
        victimWalletAddress: '',
        scammerWalletAddress: '',
        chain: '',
        date: '',
        amount: '',
        currency: '',
        transactionID: '',
        transactionScreenshots: [],
      },
    ],
    websites: [{ url: '' }],
    tokenIDs: [{ tokenID: '' }],
    ipAddresses: [{ ip: '' }],
    contacts: [{ contact: '' }],
  });

  // 更新資料變更函數
  const handleChange = (input) => (e) => {
    const value = e && e.target ? e.target.value : e;
    setFormData((prevData) => ({ ...prevData, [input]: value }));
  };

  // 下一步
  const nextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  // 上一步
  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // 提交表單
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // 處理文字編輯器的內容
    formDataToSend.append('description', JSON.stringify(formData.description));

    // 處理其他資料
    Object.keys(formData).forEach((key) => {
      if (key === 'evidenceFiles') {
        // 上傳證據檔案
        formData.evidenceFiles.forEach((file) => {
          if (file) {
            formDataToSend.append('evidenceFiles', file);
          }
        });
      } else if (key === 'scammerAddresses') {
        // 上傳交易截圖
        formData.scammerAddresses.forEach((address, index) => {
          if (address.transactionScreenshots && address.transactionScreenshots[0]) {
            formDataToSend.append(`transactionScreenshots[${index}]`, address.transactionScreenshots[0]);
          }
        });
        // 移除 transactionScreenshots，避免在 JSON.stringify 時出錯
        const scammerAddressesWithoutFiles = formData.scammerAddresses.map(
          ({ transactionScreenshots, ...rest }) => rest
        );
        formDataToSend.append('scammerAddresses', JSON.stringify(scammerAddressesWithoutFiles));
      } else if (key !== 'description') {
        formDataToSend.append(key, JSON.stringify(formData[key]));
      }
    });

    try {
      const response = await axios.post(`${API_URL}/api/report`, formDataToSend, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true, // 發送 cookie 沒有這行則無法確認用戶有沒有登入
      });
      console.log(document.cookie); // 檢查是否有 cookie
  
      
      if (response.data.guestId) {
        localStorage.setItem('guestId', response.data.guestId); // 將 guestId 存入 localStorage
        // 提示用戶記錄 guestId，並告訴他們可以在登入後關聯該報案
        alert(`${t('reportSubmitted')} - ${t('guestIdNote')}: ${response.data.guestId}`);
      } else {
        alert(t('reportSubmitted'));
      }
  
      navigate('/');
    } catch (error) {
      console.error('Error submitting report:', error);
    }
  };



  const steps = [
    <Step1
      nextStep={nextStep}
      handleChange={handleChange}
      values={formData}
      setFormData={setFormData}
    />,
    <Step2
      nextStep={nextStep}
      prevStep={prevStep}
      handleChange={handleChange}
      values={formData}
      setFormData={setFormData}
    />,
    <Step3
      nextStep={nextStep}
      prevStep={prevStep}
      handleChange={handleChange}
      values={formData}
      setFormData={setFormData}
    />,
    <Step4
      prevStep={prevStep}
      handleSubmit={handleSubmit}
      values={formData}
      handleChange={handleChange}
      setFormData={setFormData}
    />,
  ];

  return (
    <div className="container mx-auto py-8">
      <StepIndicator currentStep={currentStep} />
      <form onSubmit={handleSubmit}>{steps[currentStep - 1]}</form>
    </div>
  );
}

export default Report;
