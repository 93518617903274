import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API_URL from '../config';

function Favorites() {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    // 取得用戶的收藏列表
    axios.get(`${API_URL}/api/favorites`, { withCredentials: true })
      .then((res) => {
        setFavorites(res.data.favorites);
      })
      .catch((err) => {
        console.error('取得收藏列表失敗', err);
      });
  }, []);

  return (
    <div className="favorites-container">
      <h2>收藏列表</h2>
      {favorites.map((item) => (
        <div key={item._id} className="favorite-item">
          <p>報案編號：{item._id}</p>
          <p>事件類型：{item.incidentType}</p>
          {/* 其他資訊 */}
        </div>
      ))}
    </div>
  );
}

export default Favorites;
