import React from 'react';
import { useTranslation } from 'react-i18next';

function Step4({ prevStep, handleSubmit, values, handleChange, setFormData }) {
  const { t } = useTranslation();

  // 處理個人資料變更
  const handlePersonalInfoChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  // 顯示上傳的證據文件名稱
  const renderEvidenceFiles = () => {
    return values.evidenceFiles.map((file, index) => (
      <li key={index}>
        {file ? file.name : t('noFile')}
      </li>
    ));
  };

  // 顯示交易截圖文件名稱
  const renderTransactionScreenshots = (screenshots) => {
    return screenshots.map((file, index) => (
      <li key={index}>
        {file ? file.name : t('noFile')}
      </li>
    ));
  };

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-white rounded-lg shadow-md">
      {/* 確認信息區塊 */}
      <h2 className="text-3xl mb-6 font-bold text-gray-900 dark:text-gray-100">{t('confirmAndSubmit')}</h2>
      <p className="text-gray-600 dark:text-gray-400 mb-8">{t('pleaseConfirmInfo')}</p>

      {/* 顯示先前的所有資訊 */}
      <div className="mb-10">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-gray-200">{t('reviewYourInformation')}</h3>
        
        <ul className="space-y-6 text-gray-700 dark:text-gray-300">
          {/* 事件類型 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('incidentType')}:</span> {values.incidentType === 'other' ? values.otherIncidentType : t(values.incidentType)}
          </li>

          {/* 詐騙者地址列表 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('scammerAddresses')}:</span>
            <ul className="list-decimal pl-5 mt-2 space-y-2">
              {values.scammerAddresses.map((address, index) => (
                <li key={index} className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">
                  <div><span className="font-semibold">{t('victimWalletAddress_step4')}:</span> {address.victimWalletAddress}</div>
                  <div><span className="font-semibold">{t('scammerWalletAddress_step4')}:</span> {address.scammerWalletAddress}</div>
                  <div><span className="font-semibold">{t('chain')}:</span> {address.chain}</div>
                  <div><span className="font-semibold">{t('date')}:</span> {address.date}</div>
                  <div><span className="font-semibold">{t('amount')}:</span> {address.amount}</div>
                  <div><span className="font-semibold">{t('currency')}:</span> {address.currency}</div>
                  <div><span className="font-semibold">{t('transactionID')}:</span> {address.transactionID}</div>

                  {/* 顯示交易截圖文件名稱 */}
                  {address.transactionScreenshots && address.transactionScreenshots.length > 0 && (
                    <div>
                      <span className="font-semibold">{t('transactionScreenshots')}:</span>
                      <ul className="list-disc pl-5 mt-1">
                        {renderTransactionScreenshots(address.transactionScreenshots)}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </li>

          {/* 其他區塊保持不變 */}
          {/* 網站列表 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('websites')}:</span>
            <ul className="list-decimal pl-5 mt-2 space-y-2">
              {values.websites.map((website, index) => (
                <li key={index} className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">{website.url}</li>
              ))}
            </ul>
          </li>

          {/* 代幣 ID 列表 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('tokenIDs')}:</span>
            <ul className="list-decimal pl-5 mt-2 space-y-2">
              {values.tokenIDs.map((token, index) => (
                <li key={index} className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">{token.tokenID}</li>
              ))}
            </ul>
          </li>

          {/* IP 地址列表 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('ipAddresses')}:</span>
            <ul className="list-decimal pl-5 mt-2 space-y-2">
              {values.ipAddresses.map((ip, index) => (
                <li key={index} className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">{ip.ip}</li>
              ))}
            </ul>
          </li>

          {/* 聯絡方式列表 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('contacts')}:</span>
            <ul className="list-decimal pl-5 mt-2 space-y-2">
              {values.contacts.map((contact, index) => (
                <li key={index} className="bg-gray-100 dark:bg-gray-800 p-3 rounded-md">{contact.contact}</li>
              ))}
            </ul>
          </li>

          {/* 案件描述 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('description')}:</span>
            <div className="mt-2 p-3 bg-gray-100 dark:bg-gray-800 rounded-md whitespace-pre-wrap">
              {values.description && values.description.blocks
                ? values.description.blocks.map(block => block.text).join('\n')
                : values.description}
            </div>
          </li>

          {/* 上傳的證據文件 */}
          <li className="border-b border-gray-200 dark:border-gray-700 pb-4">
            <span className="font-semibold">{t('uploadedFiles')}:</span>
            <ul className="list-decimal pl-5 mt-2 space-y-2">
              {renderEvidenceFiles()}
            </ul>
          </li>

        </ul>
      </div>

      {/* 個人資料輸入區塊（保持不變） */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">{t('personalInformation')}</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">{t('personalInfoDescription')}</p>
        <div className="flex flex-wrap -mx-2">
          {/* 全名 */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">
              {t('fullName')}<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder={t('enterFullName')}
              value={values.fullName || ''}
              onChange={(e) => handlePersonalInfoChange('fullName', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
            />
          </div>
          {/* 電子郵件 */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">
              {t('email')}<span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              placeholder={t('enterEmail')}
              value={values.email || ''}
              onChange={(e) => handlePersonalInfoChange('email', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
            />
          </div>
          {/* 電話號碼 */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('phoneNumber')}</label>
            <input
              type="tel"
              placeholder={t('enterPhoneNumber')}
              value={values.phoneNumber || ''}
              onChange={(e) => handlePersonalInfoChange('phoneNumber', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
            />
          </div>
          {/* 國家 */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('country')}</label>
            <input
              type="text"
              placeholder={t('enterCountry')}
              value={values.country || ''}
              onChange={(e) => handlePersonalInfoChange('country', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
            />
          </div>
          {/* 城市 */}
          <div className="w-full md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('city')}</label>
            <input
              type="text"
              placeholder={t('enterCity')}
              value={values.city || ''}
              onChange={(e) => handlePersonalInfoChange('city', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-800 dark:text-white"
            />
          </div>
        </div>
      </div>

      {/* 導航按鈕 */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
        >
          {t('previous')}
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
        >
          {t('submitReport')}
        </button>
      </div>
    </div>
  );
}

export default Step4;
