import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScamQuiz from './ScamQuiz';


function Step1({ nextStep, handleChange, values }) {
  const { t } = useTranslation();
  const [showQuiz, setShowQuiz] = useState(false); // 控制詐騙問卷的顯示
  const [showOtherInput, setShowOtherInput] = useState(false); // 控制“其他”輸入框的顯示


  // 檢查是否選擇了 "其他" 來顯示額外的輸入框
  const handleIncidentTypeChange = (e) => {
    handleChange('incidentType')(e);
    if (e.target.value === 'other') {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
    }
  };

  const continueStep = (e) => {
    e.preventDefault();
    if (!values.incidentType) {
      alert(t('pleaseSelectIncidentType'));
    } else if (!values.reportType) {
      alert(t('pleaseSelectReportType')); // 提示用戶選擇報告分享方式
    } else {
      nextStep();
    }
  };

  return (
    <div>
      <h2 className="text-2xl mb-4">{t('selectIncidentType')}</h2>

      <select
        name="incidentType"
        value={values.incidentType}
        onChange={handleIncidentTypeChange} // 使用自定義的 handleIncidentTypeChange 方法
        className="w-full p-2 border rounded mb-4 bg-white text-gray-900 dark:bg-gray-800 dark:text-white dark:border-gray-700"
      >
        <option value="">{t('pleaseSelect')}</option>
        <option value="blackmailSextortion">{t('blackmailSextortion')}</option>
        <option value="blackmailRansomware">{t('blackmailRansomware')}</option>
        <option value="blackmailOther">{t('blackmailOther')}</option>
        <option value="fraudNftAirdrop">{t('fraudNftAirdrop')}</option>
        <option value="fraudPhishing">{t('fraudPhishing')}</option>
        <option value="fraudImpersonation">{t('fraudImpersonation')}</option>
        <option value="fraudDonation">{t('fraudDonation')}</option>
        <option value="fraudRomance">{t('fraudRomance')}</option>
        <option value="fraudPigButchering">{t('fraudPigButchering')}</option>
        <option value="fraudFakeProject">{t('fraudFakeProject')}</option>
        <option value="fraudRugPull">{t('fraudRugPull')}</option>
        <option value="fraudFakeReturns">{t('fraudFakeReturns')}</option>
        <option value="fraudOther">{t('fraudOther')}</option>
        <option value="hackSimSwap">{t('hackSimSwap')}</option>
        <option value="hackContractExploit">{t('hackContractExploit')}</option>
        <option value="hackOther">{t('hackOther')}</option>
        <option value="other">{t('other')}</option>
      </select>

      {/* 動態顯示的輸入框，當選擇 "其他" 時顯示 */}
      {showOtherInput && (
        <div className="mb-4">
          <label htmlFor="otherIncidentType" className="block mb-2 text-gray-900 dark:text-white">{t('pleaseSpecify')}</label>
          <input
            type="text"
            id="otherIncidentType"
            name="otherIncidentType"
            value={values.otherIncidentType || ''}
            onChange={handleChange('otherIncidentType')}
            className="w-full p-2 border rounded bg-white text-gray-900 dark:bg-gray-800 dark:text-white dark:border-gray-700"
            placeholder={t('pleaseSpecifyIncident')}
          />
        </div>
      )}

        {/* 詐騙問卷 */}
        <div className="bg-white text-gray-900 p-6 rounded-lg shadow-lg my-6 dark:bg-gray-800 dark:text-white">
            <h2 className="text-xl font-bold mb-4">{t('notSureWhatToChoose')}</h2>
            <p className="text-gray-700 mb-4 dark:text-gray-300">{t('takeQuizDescription')}</p>
            <button
                type="button"
                onClick={() => setShowQuiz(true)}
                className="text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white font-semibold py-2 px-4 rounded transition duration-300 dark:border-blue-300 dark:text-blue-300 dark:hover:bg-blue-300 dark:hover:text-black"
            >
                {t('takeScamQuiz')}
            </button>
        </div>

        {/* 顯示 Scam Quiz */}
        {showQuiz && (
            <ScamQuiz onClose={() => setShowQuiz(false)} setIncidentType={handleChange('incidentType')} />
        )}



      {/* 報告分享選項 */}
      <div className="mb-4">
        <label className="block text-2xl mb-2">{t('reportSharing')}</label>
        <div>
          <input
            type="radio"
            id="public"
            name="reportType"
            value="public"
            onChange={handleChange('reportType')}
            checked={values.reportType === 'public'}
          />
          <label htmlFor="public" className="ml-2">{t('postReportOnDamenKYT')}</label>
          <p className="text-xs text-gray-600 mt-1 ml-6">
            {t('publicReportInfo')}
          </p>
        </div>
        <div>
          <input
            type="radio"
            id="private"
            name="reportType"
            value="private"
            onChange={handleChange('reportType')}
            checked={values.reportType === 'private'}
          />
          <label htmlFor="private" className="ml-2">{t('submitReportPrivately')}</label>
        </div>
      </div>

      <button onClick={continueStep} className="bg-blue-500 text-white px-4 py-2 rounded">
        {t('next')}
      </button>
    </div>
  );
}

export default Step1;
