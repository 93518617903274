// src/pages/WalletRisk.js
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';

function WalletRisk() {
  const [walletAddress, setWalletAddress] = useState('');
  const [riskData, setRiskData] = useState(null);
  const [chainId, setChainId] = useState(1);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!walletAddress) return;

    try {
      const response = await axios.post(`${API_URL}/api/predict-risk`, 
        { walletAddress, chain_id: chainId }, 
        { withCredentials: true });
      setRiskData(response.data);
      console.log("API 回傳 JSON:", response.data); // 調試用，印出完整的 JSON
    } catch (error) {
      console.error('Error fetching risk data:', error);
      alert(error.response.data.error || "無法查詢該地址的風險資訊。");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-6">{t('walletRiskAssessment')}</h1>
      <form onSubmit={handleSubmit} className="w-full max-w-md">
        <input
          type="text"
          placeholder={t('inputWalletAddress')}
          value={walletAddress}
          onChange={(e) => setWalletAddress(e.target.value)}
          className="w-full p-3 border rounded mb-4"
        />

        {/* 下拉選單：選擇鏈 */}
        <select 
          value={chainId} 
          onChange={(e) => setChainId(e.target.value)} 
          className="w-full p-3 border rounded mb-4"
        >
          <option value="-3">Solana</option>
          <option value="-2">Tron</option>
          <option value="-1">Bitcoin Mainnet</option>
          <option value="1">Ethereum Mainnet</option>
          <option value="10">Optimism Mainnet</option>
          <option value="25">Cronos Mainnet</option>
          <option value="56">BNB Smart Chain Mainnet</option>
          <option value="100">Gnosis</option>
          <option value="137">Polygon Mainnet</option>
          <option value="199">BitTorrent Chain Mainnet</option>
          <option value="250">Fantom</option>
          <option value="288">Boba Network</option>
          <option value="324">zkSync Era Mainnet</option>
          <option value="1024">CLV Parachain</option>
          <option value="1101">Polygon zkEvm</option>
          <option value="1111">WEMIX3.0 Mainnet</option>
          <option value="1284">Moonbeam</option>
          <option value="1285">Moonriver</option>
          <option value="8453">Base</option>
          <option value="42161">Arbitrum One</option>
          <option value="42220">Celo Mainnet</option>
          <option value="43114">Avalanche C-Chain</option>
          <option value="59144">Linea</option>
          <option value="81457">Blast Mainnet</option>
          <option value="1313161554">Aurora Mainnet</option>
        </select>


        <button type="submit" className="w-full bg-blue-500 text-white p-3 rounded">
          {t('assessRisk')}
        </button>
      </form>
      
      {/* 顯示 API 返回的風險數據 */}
      {riskData && (
        <div className="mt-6">
          <h2 className="text-2xl font-bold">錢包標籤:</h2>
          <p><strong>Main Entity:</strong> {riskData.data.main_entity || '未知'}</p>
          <p><strong>Name Tag:</strong> {riskData.data.name_tag || '未知'}</p>
          {riskData.data?.attributes && riskData.data.attributes.length > 0 ? (
            <ul>
              {riskData.data.attributes.map((attr, index) => (
                <li key={index}>{attr.name}</li>
              ))}
            </ul>
          ) : (
            <p>無法找到該錢包地址的標籤。</p>
          )}
        </div>
      )}
    </div>
  );
}

export default WalletRisk;
