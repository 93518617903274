import React, { useState } from 'react';
import '../../App.css';
import { useTranslation } from 'react-i18next';

function ScamQuiz({ onClose, setIncidentType }) {
  const { t } = useTranslation();

  // Define the quiz steps and their respective options
  const quizSteps = {
    '1': {
      question: t('howDidItOccur'),
      options: [
        { label: t('blackmailedSendCrypto'), value: 'blackmail' },
        { label: t('trickedTransferCrypto'), value: 'fraud' },
        { label: t('unauthorizedPayment'), value: 'hack' },
      ],
    },
    'blackmail': {
      question: t('specifyBlackmailType'),
      options: [
        { label: t('blackmailSextortion_description'), value: 'blackmailSextortion' },
        { label: t('blackmailRansomware_description'), value: 'blackmailRansomware' },
        { label: t('blackmailOther'), value: 'blackmailOther' },
      ],
    },
    'fraud': {
      question: t('specifyFraudType'),
      options: [
        { label: t('fraudNftAirdrop_description'), value: 'fraudNftAirdrop' },
        { label: t('fraudPhishing_description'), value: 'fraudPhishing' },
        { label: t('fraudImpersonation_description'), value: 'fraudImpersonation' },
        { label: t('fraudDonation_description'), value: 'fraudDonation' },
        { label: t('fraudRomance_description'), value: 'fraudRomance' },
        { label: t('fraudPigButchering_description'), value: 'fraudPigButchering' },
        { label: t('fraudFakeProject_description'), value: 'fraudFakeProject' },
        { label: t('fraudRugPull_description'), value: 'fraudRugPull' },
        { label: t('fraudFakeReturns_description'), value: 'fraudFakeReturns' },
        { label: t('fraudOther'), value: 'fraudOther' },
      ],
    },
    'hack': {
      question: t('specifyHackType'),
      options: [
        { label: t('hackSimSwap_description'), value: 'hackSimSwap' },
        { label: t('hackContractExploit_description'), value: 'hackContractExploit' },
        { label: t('hackOther'), value: 'hackOther' },
      ],
    },
  };

  // State to keep track of the quiz history and user selections
  const [history, setHistory] = useState(['1']);
  const [answers, setAnswers] = useState({});

  const currentStepKey = history[history.length - 1];
  const currentStepData = quizSteps[currentStepKey];

  const handleOptionSelect = (value) => {
    setAnswers({ ...answers, [history.length]: value });

    if (quizSteps[value]) {
      // Proceed to the next step if available
      setHistory([...history, value]);
    } else {
      // Final selection made, update incident type and close modal
      setIncidentType(value);
      onClose();
    }
  };

  const handlePrevious = () => {
    if (history.length > 1) {
      // Go back to the previous step
      setHistory(history.slice(0, -1));
    } else {
      // If on the first step, close the modal
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 dark:bg-opacity-80">
      <div className="bg-white dark:bg-gray-800 w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="py-6 px-8">
          <h3 className="text-xl font-bold mb-6 dark:text-white">{currentStepData.question}</h3>
          {currentStepData.options.map((option) => (
            <button
              key={option.value}
              type="button"
              onClick={() => handleOptionSelect(option.value)}
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mb-4 w-full text-left"
            >
              {option.label}
            </button>
          ))}
          <div className="flex justify-between mt-8">
            <button
              type="button"
              onClick={handlePrevious}
              className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded"
            >
              {t('previous')}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
            >
              {t('close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScamQuiz;
