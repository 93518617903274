import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import API_URL from '../config';

function Navbar() {
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // 控制會員專區下拉選單
  let timeoutId = null;

  const googleLoginUrl = process.env.NODE_ENV === 'production'
    ? `${API_URL}/api/auth/google`
    : 'http://localhost:5002/api/auth/google';

  const googleLogoutUrl = process.env.NODE_ENV === 'production'
    ? `${API_URL}/api/logout`
    : 'http://localhost:5002/api/logout';

  useEffect(() => {
    async function checkAuth() {
      try {
        const response = await axios.get(`${API_URL}/api/current_user`, { withCredentials: true });
        if (response.data) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setAuthChecked(true);
      }
    }
    checkAuth();
  }, []);

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200); // 200ms 延遲
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutId); // 取消之前的延遲任務
    setIsDropdownOpen(true);
  };

  return (
    <nav className="bg-gray-800 p-4 text-white">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="text-2xl font-bold">
          DamenKYT
        </Link>

        <div className="space-x-4 flex items-center">
          <Link to="/report" className="hover:text-gray-300 transition-colors">{t('generateReport')}</Link>
          <Link to="/wallet-risk" className="hover:text-gray-300 transition-colors">{t('walletRiskQuery')}</Link>
          {/* <Link to="/ai-prediction" className="hover:text-gray-300 transition-colors">{t('aiRiskPrediction')}</Link> */}
          {/* <Link to="/academy" className="hover:text-gray-300 transition-colors">{t('blockchainAcademy')}</Link> */}

          {authChecked && isAuthenticated && (
            <div 
              className="relative" 
              onMouseEnter={handleMouseEnter} 
              onMouseLeave={handleMouseLeave}
            >
              <button className="hover:text-gray-300 transition-colors">
                {t('memberArea')}
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg z-10">
                  <ul className="py-2 text-gray-700">
                    <li>
                      <Link 
                        to="/dashboard" 
                        className="block px-4 py-2 hover:bg-gray-100 transition-colors rounded-t-lg"
                      >
                        {t('dashboard')}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/profile" 
                        className="block px-4 py-2 hover:bg-gray-100 transition-colors"
                      >
                        {t('memberInfo')}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/my-reports" 
                        className="block px-4 py-2 hover:bg-gray-100 transition-colors"
                      >
                        {t('myReports')}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/path-analysis" 
                        className="block px-4 py-2 hover:bg-gray-100 transition-colors"
                      >
                        {t('pathAnalysis')}
                      </Link>
                    </li>
                    <li>
                      <Link 
                        to="/favorites" 
                        className="block px-4 py-2 hover:bg-gray-100 transition-colors rounded-b-lg"
                      >
                        {t('favorites')}
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}

          {authChecked && (
            isAuthenticated ? (
              <a href={googleLogoutUrl} className="border border-red-500 text-red-500 p-2 rounded hover:bg-red-500 hover:text-white transition-colors">
                Logout
              </a>
            ) : (
              <a href={googleLoginUrl} className="border border-blue-500 text-blue-500 p-2 rounded hover:bg-blue-500 hover:text-white transition-colors">
                Login
              </a>
            )
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
