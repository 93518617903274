import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';

function Profile() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  
  const fetchUser = useCallback(async () => {
    try {
      const res = await axios.get(`${API_URL}/api/current_user`, {
        withCredentials: true // 確保 cookie 被傳遞
      });
      if (res.data) {
        setUser(res.data);
        setPhoneNumber(res.data.phoneNumber || '');
      } else {
        navigate('/login'); // 如果未登入，重定向到登入頁面
      }
    } catch (error) {
      console.error('Error fetching user', error);
      navigate('/login'); // 如果發生錯誤，也重定向到登入頁面
    }
  }, [navigate]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleSave = () => {
    if (!/^\d{10}$/.test(phoneNumber)) {
      alert(t('enterValidPhoneNumber'));
      return;
    }

    // 更新會員資訊
    axios.post(`${API_URL}/api/update_profile`, { phoneNumber }, { withCredentials: true })
      .then((res) => {
        alert(t('updateSuccess'));
      })
      .catch((err) => {
        console.error('更新失敗', err);
        alert(t('updateFailed'));
      });
  };

  if (!user) return <div>{t('loading')}</div>;

  return (
    <div className="profile-container">
      <h2>{t('profileInfo')}</h2>
      <p>{t('email')}: {user.email}</p>
      <p>{t('name')}: {user.name}</p>
      {/* 綁定手機號碼 */}
      <label>
        {t('phoneNumber')}:
        <input
          type="text"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </label>
      <button onClick={handleSave}>{t('save')}</button>
    </div>
  );
}

export default Profile;