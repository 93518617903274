import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "walletRiskAssessment": "Wallet Risk Assessment",
      "inputWalletAddress": "Enter Wallet Address",
      "assessRisk": "Assess Risk",
      // 首頁的翻譯
      "welcomeToApp": "Welcome to the App",
      "appDescription": "This is a powerful app.",
      "guestReportInfo": "You have an unlinked report",
      "guestId": "Guest ID",
      "errorFetchingGuestReport": "Error fetching report information",
      // 導航列的翻譯
      "generateReport": "Report Your Case",
      "pathAnalysis": "Path Analysis",
      "walletRiskQuery": "Wallet Risk Query",
      "aiRiskPrediction": "AI Risk Prediction",
      "blockchainAcademy": "Blockchain Academy",
      "memberArea": "Member Area",
      "dashboard": "Dashboard",
      "memberInfo": "Member Information",
      "favorites": "My Favorites",

      // Footer 的翻譯
      "companyInfo": "Company Information",
      "kaohsiungOffice": "Kaohsiung Office",
      "kaohsiungAddress": "Ziqiang 3rd Rd., 29F-7, Lingya District, Kaohsiung City",
      "taipeiOffice": "Taipei Office",
      "taipeiAddress": "Minquan East Rd. Sec. 3, 12F, Zhongshan District, Taipei City",
      "contactPhone": "Phone",
      "contactEmail": "Email",
      "quickLinks": "Quick Links",
      "aboutUs": "About Us",
      "services": "Services",
      "reportSystem": "Report System",
      "contactUs": "Contact Us",
      "legalAndPolicy": "Legal & Policy",
      "privacyPolicy": "Privacy Policy",
      "termsOfService": "Terms of Service",
      "socialMedia": "Social Media",
      "allRightsReserved": "All Rights Reserved",

      // Report 的翻譯
        // StepIndicator 的翻譯
        "caseDetails": "Case Details",
        "scammerInformation": "Scammer Information",
        "description": "Description and Evidence Upload",
        "contactSupport": "Contact and Confirm",
        // ScamQuiz 的翻譯
        "notSureWhatToChoose": "Not sure what to choose?",
        "takeQuizDescription": "Take our Scam Quiz to help you find out.",
        "takeScamQuiz": "Take Scam Type Quiz",
        "howDidItOccur": "How did the scam occur?",
        "specifyFraudType": "Specify the type of fraud",
        "blackmailedSendCrypto": "I was blackmailed to send crypto",
        "blackmailSextortion_description": "Threatened me to release compromising sexual information",
        "blackmailRansomware_description": "Blocked my system and asked payment to release it",
        "trickedTransferCrypto": "I was tricked to transfer crypto",
        "unauthorizedPayment": "Someone made a payment I never authorized",
        "specifyBlackmailType": "Specify the type of blackmail",
        "specifyHackType": "Specify the type of hack",
        "fraudNftAirdrop_description": "Scammer distributed fake NFTs to trick victims.",
        "fraudPhishing_description": "Scammer tricked victim into revealing sensitive information via a fake website or email.",
        "fraudImpersonation_description": "Scammer pretended to be someone else to deceive victims.",
        "fraudDonation_description": "Scammer impersonated a charity to solicit fake donations.",
        "fraudRomance_description": "Scammer formed a fake romantic relationship to scam money.",
        "fraudPigButchering_description": "Scammer tricked victims into investing in fake financial schemes.",
        "fraudFakeProject_description": "Scammer promoted a fake project to steal money from investors.",
        "fraudRugPull_description": "Scammer abruptly withdrew funds from a project, leaving investors with nothing.",
        "fraudFakeReturns_description": "Scammer promised high returns on investments but never delivered.",
        "hackSimSwap_description": "Scammer took control of my phone number to access my accounts.",
        "hackContractExploit_description":"I may have interacted with a smart contract that was exploited",
        // Step1 下拉選單
        "selectIncidentType": "Select Incident Type",
        "pleaseSelect": "Please Select",
        "blackmailSextortion": "Blackmail - Sextortion Scam",
        "blackmailRansomware": "Blackmail - Ransomware",
        "blackmailOther": "Blackmail - Other",
        "fraudNftAirdrop": "Fraud - NFT Airdrop Scam",
        "fraudPhishing": "Fraud - Phishing Scam",
        "fraudImpersonation": "Fraud - Impersonation Scam",
        "fraudDonation": "Fraud - Donation Impersonation Scam",
        "fraudRomance": "Fraud - Romance Scam",
        "fraudPigButchering": "Fraud - Pig Butchering Scam",
        "fraudFakeProject": "Fraud - Fake Project Investment Scam",
        "fraudRugPull": "Fraud - Rug Pull Investment Scam",
        "fraudFakeReturns": "Fraud - Fake Returns Investment Scam",
        "fraudOther": "Fraud - Other",
        "hackSimSwap": "Hack - SIM Swap",
        "hackContractExploit": "Hack - Contract Exploit",
        "hackOther": "Hack - Other",
        "other": "Other",
        "pleaseSpecify": "Please specify",
        "pleaseSpecifyIncident": "Please specify the incident type",
        "reportSharing": "Report Sharing",
        "postReportOnDamenKYT": "Post report on DamenKYT",
        "publicReportInfo": "Most users choose to make their report public to alert the community. All personal information will remain private. DamenKYT will never share your personal details on the platform.",
        "submitReportPrivately": "Submit report privately",
        "pleaseSelectIncidentType": "Please select an incident type",
        "pleaseSelectReportType": "Please select a report sharing option",
        // Step2.js 的翻譯
        "scammerInfoDescription": "Please provide as much information as possible about the scammer.",
        "transactionDetails": "Transaction Details",
        "transactionDetailsDescription": "Enter the transaction details when funds were transferred from your wallet to the scammer's wallet. If there are multiple transactions, click the button below to add more transactions.",
        "victimWalletAddress": "Victim's Wallet Address (Required)",
        "enterVictimWalletAddress": "Enter victim's wallet address",
        "scammerWalletAddress": "Scammer's Wallet Address (Required)",
        "enterWalletAddress": "Enter scammer's wallet address",
        "selectChain": "Select Blockchain",
        "dateOfFraud": "Date of Fraud",
        "amount": "Amount",
        "enterAmount": "Enter amount",
        "currency": "Currency",
        "enterCurrency": "Enter currency",
        "transactionID": "Transaction ID (Hash Value)",
        "uploadTransactionScreenshot": "Upload Transaction Screenshot",
        "enterTransactionID": "Enter transaction ID",
        "addTransactions": "Add More Transactions",
        "websiteUsedByScammer": "Website (URL) Used by the Scammer",
        "websiteDescription": "Provide any websites associated with the scammer.",
        "enterWebsiteURL": "Enter website URL",
        "addWebsite": "Add Website",
        "stolenTokenID": "Stolen Token ID",
        "tokenIDDescription": "List any token IDs that were stolen.",
        "enterTokenID": "Enter token ID",
        "addTokenID": "Add Token ID",
        "scammerIpAddress": "Scammer IP Address",
        "ipAddressDescription": "Provide any known IP addresses of the scammer.",
        "enterIpAddress": "Enter IP address",
        "addIpAddress": "Add IP Address",
        "scammerContact": "Scammer Contact (if available)",
        "contactDescription": "Any contact information of the scammer.",
        "enterScammerContact": "Enter scammer contact",
        "addContact": "Add Contact",
        // Step3.js 的翻譯
        "provideEvidence": "Provide Evidence",
        "descriptionPlaceholder": "Please describe the case with as many details as possible to support investigations. If you are posting publicly, make sure not to enter any personal information here.",
        "enterDescription": "Enter case description",
        "uploadEvidence": "Upload Evidence(Max 10)",
        "addFile": "Add File",

        // Step4.js 的翻譯
        "pleaseConfirmInfo": "Please confirm the following information and provide your personal details.",
        "reviewYourInformation": "Review Your Information",
        "personalInformation": "Personal Information",
        "personalInfoDescription": "Please fill in the following personal details so we can contact you.",
        "fullName": "Full Name",
        "enterFullName": "Enter your full name",
        "email": "Email",
        "enterEmail": "Enter your email",
        "phoneNumber": "Phone Number",
        "enterPhoneNumber": "Enter your phone number",
        "country": "Country",
        "enterCountry": "Enter your country",
        "city": "City",
        "enterCity": "Enter your city",
        "uploadedFiles": "Uploaded Files",
        "scammerAddresses": "Scammer Addresses",
        "victimWalletAddress_step4": "Victim's Wallet Address",
        "scammerWalletAddress_step4": "Scammer's Wallet Address",
        "chain": "Blockchain",
        "date": "Date",
        "websites": "Websites",
        "tokenIDs": "Token IDs",
        "ipAddresses": "IP Addresses",
        "contacts": "Contacts",
        "noFile": "No file uploaded",
        "transactionScreenshots": "Transaction Screenshots",

        // ReportSteps 共用的翻譯
        "next": "Next",
        "previous": "Previous",
        "confirmAndSubmit": "Confirm and Submit",
        "submitReport": "Submit Report",
        "reportSubmitted": "Report Submitted Successfully",
        "incidentType": "Incident Type",
        "exchange": "Exchange",
        "pleaseCompleteFields": "Please complete all required fields",
        "selectedFile": "Selected File",
        "remove": "Remove",
        "guestIdNote": "Note: You are currently browsing as a guest. Please log in to save your report.",

      // Profile.js 的翻譯
      "loading": "Loading...",
      "profileInfo": "Profile Information",
      "name": "Name",
      "save": "Save",
      "enterValidPhoneNumber": "Please enter a valid phone number",
      "updateSuccess": "Update successful",
      "updateFailed": "Update failed, please try again later",

      // MyReports.js 的翻譯
      "myReports": "My Reports",
      "reportId": "Report ID",
      "exportAsPDF": "Export as PDF",
      "exportAsJSON": "Export as JSON",
      "addToFavorites": "Add to Favorites",
      "addedToFavorites": "Added to Favorites",
      "linkGuestReport": "Link Guest Report",
      "enterGuestId": "Enter Guest ID",
      "linkReport": "Link Report",
      "reportLinked": "Report successfully linked to your account",
      "errorLinkingReport": "Failed to link report, please check your Guest ID",
      "errorFetchingReports": "Error fetching reports",
      "errorAddingToFavorites": "Error adding to favorites",
    
    
    
    }

  },
  zh: {
    translation: {
      // WalletRiskAssessment.js 的翻譯
      "walletRiskAssessment": "錢包地址風險評估",
      "report": "填寫交易報告",
      "riskAssessment": "評估風險",
      "inputWalletAddress": "輸入錢包地址",
      "assessRisk": "評估風險",
      // 首頁的翻譯
      "welcomeToApp": "歡迎來到 DamenKYT",
      "appDescription": "在這個數位時代，安全和信任變得比以往更加重要。DamenKYT 專注於提供最先進的報案和交易監控服務，致力於保護每一位用戶免於遭受詐騙與網絡攻擊。我們的目標是通過簡單、透明且高效的系統，協助用戶快速提交報告，並追蹤可疑活動，確保每一筆交易的安全。我們的專業團隊擁有豐富的區塊鏈與反詐騙技術經驗，致力於建立一個更安全的網絡環境。無論是個人還是企業，DamenKYT都是您可信賴的夥伴，幫助您保護自己的資產與數位身份。探索更多，加入我們，讓您的數位世界更加安全。",
      "guestReportInfo": "您有未關聯的報案",
      "guestId": "訪客 ID",
      "errorFetchingGuestReport": "獲取報案資訊時出錯",
      // 導航列的翻譯
      "generateReport": "報告您的案例",
      "pathAnalysis": "受騙路徑分析",
      "walletRiskQuery": "錢包查詢",
      "aiRiskPrediction": "AI風險預測",
      "blockchainAcademy": "區塊鏈學院",
      "memberArea": "會員專區",
      "dashboard": "儀表板",
      "memberInfo": "會員資訊",
      "favorites": "我的收藏",

      // Footer 的翻譯
      "companyInfo": "公司資訊",
      "kaohsiungOffice": "高雄辦公室",
      "kaohsiungAddress": "苓雅區自強三路5號29樓之7",
      "taipeiOffice": "台北辦公室",
      "taipeiAddress": "中山區民權東路三段37號12樓",
      "contactPhone": "電話",
      "contactEmail": "電郵",
      "quickLinks": "快速導航",
      "aboutUs": "關於我們",
      "services": "服務",
      "reportSystem": "報案系統",
      "contactUs": "聯絡我們",
      "legalAndPolicy": "法律與政策",
      "privacyPolicy": "隱私政策",
      "termsOfService": "服務條款",
      "socialMedia": "社交媒體",
      "allRightsReserved": "版權所有",

      // ReportSteps 的翻譯
        // StepIndicator 的翻譯
        "caseDetails": "案件詳情",
        "scammerInformation": "詐騙者資訊",
        "description": "描述及證據上傳",
        "contactSupport": "聯繫及確認",
        // ScamQuiz 的翻譯
        "notSureWhatToChoose": "不確定該選什麼嗎？",
        "takeQuizDescription": "進行詐騙測驗，幫助您找到答案。",
        "takeScamQuiz": "詐騙類型測驗",
        "howDidItOccur": "詐騙是如何發生的？",
        "specifyFraudType": "指定詐騙類型",
        "blackmailedSendCrypto": "我被勒索發送加密貨幣",
        "blackmailSextortion_description": "威脅我公開敏感的性資訊",
        "blackmailRansomware_description": "封鎖我的系統並要求付款才能解鎖",
        "trickedTransferCrypto": "我被騙轉移加密貨幣",
        "unauthorizedPayment": "有人未經我授權進行了付款",
        "specifyBlackmailType": "請具體說明勒索類型",
        "specifyHackType": "請指定駭客類型",
        "fraudNftAirdrop_description": "詐騙者發送虛假的NFT以騙取受害者。",
        "fraudPhishing_description": "詐騙者通過假網站或電子郵件誘騙受害者泄露敏感信息。",
        "fraudImpersonation_description": "詐騙者冒充他人以欺騙受害者。",
        "fraudDonation_description": "詐騙者冒充慈善機構來騙取捐款。",
        "fraudRomance_description": "詐騙者通過虛假戀愛關係騙取錢財。",
        "fraudPigButchering_description": "詐騙者誘騙受害者投資虛假的財務計劃。",
        "fraudFakeProject_description": "詐騙者推廣虛假項目以騙取投資者的錢財。",
        "fraudRugPull_description": "詐騙者突然從項目中提取資金，讓投資者一無所獲。",
        "fraudFakeReturns_description": "詐騙者承諾高回報但從未兌現。",
        "hackSimSwap_description": "駭客控制了我的手機號碼以訪問我的帳戶。",
        "hackContractExploit_description":"我可能與被利用的智能合約互動",
        // Step1 下拉選單
        "selectIncidentType": "選擇事件類型",
        "pleaseSelect": "請選擇",
        "blackmailSextortion": "勒索 - 色情敲詐",
        "blackmailRansomware": "勒索 - 勒索軟體",
        "blackmailOther": "勒索 - 其他",
        "fraudNftAirdrop": "詐騙 - NFT 空投詐騙",
        "fraudPhishing": "詐騙 - 網絡釣魚",
        "fraudImpersonation": "詐騙 - 假冒詐騙",
        "fraudDonation": "詐騙 - 捐款詐騙",
        "fraudRomance": "詐騙 - 愛情詐騙",
        "fraudPigButchering": "詐騙 - 殺豬盤詐騙",
        "fraudFakeProject": "詐騙 - 假投資項目",
        "fraudRugPull": "詐騙 - 拉地毯詐騙(虛擬資產投資騙局)",
        "fraudFakeReturns": "詐騙 - 假投資回報",
        "fraudOther": "詐騙 - 其他",
        "hackSimSwap": "駭客 - SIM劫持",
        "hackContractExploit": "駭客 - 合約漏洞利用",
        "hackOther": "駭客 - 其他",
        "other": "其他",
        "pleaseSpecify": "請具體說明",
        "pleaseSpecifyIncident": "請具體說明事件類型",
        "reportSharing": "報告分享",
        "postReportOnDamenKYT": "在 DamenKYT上發佈報告",
        "publicReportInfo": "大多數用戶選擇公開報告以警告社群。所有個人信息將保持隱私。DamenKYT永遠不會在平台上分享您的個人訊息。",
        "submitReportPrivately": "僅私下提交報告",
        "pleaseSelectIncidentType": "請選擇事件類型",
        "pleaseSelectReportType": "請選擇報告分享方式",
        // Step2.js 的翻譯
        "scammerInfoDescription": "請盡可能提供有關詐騙者的詳細資訊。",
        "transactionDetails": "受騙的交易內容",
        "transactionDetailsDescription": "請輸入資金從您的錢包轉移到詐騙者錢包時的交易內容，如有多筆交易請點選下方添加更多交易。",
        "victimWalletAddress": "受害者的錢包地址（必填）",
        "enterVictimWalletAddress": "輸入受害者的錢包地址",
        "scammerWalletAddress": "詐騙者的錢包地址（必填）",
        "enterWalletAddress": "輸入詐騙者錢包地址",
        "selectChain": "選擇區塊鏈",
        "dateOfFraud": "受騙的時間",
        "amount": "金額",
        "enterAmount": "輸入金額",
        "currency": "幣種",
        "enterCurrency": "輸入幣種",
        "transactionID": "交易ID（哈希值）",
        "uploadTransactionScreenshot": "上傳交易截圖",
        "enterTransactionID": "輸入交易ID",
        "addTransactions": "添加更多交易",
        "websiteUsedByScammer": "詐騙者使用的網站（URL）",
        "websiteDescription": "提供與詐騙者相關的任何網站。",
        "enterWebsiteURL": "輸入網站URL",
        "addWebsite": "添加網站",
        "stolenTokenID": "被盜的代幣ID",
        "tokenIDDescription": "如果您有任何被盜的NFT，請填寫該代幣的ID。",
        "enterTokenID": "輸入代幣ID",
        "addTokenID": "添加代幣ID",
        "scammerIpAddress": "詐騙者的IP地址",
        "ipAddressDescription": "提供詐騙者的任何已知IP地址。",
        "enterIpAddress": "輸入IP地址",
        "addIpAddress": "添加IP地址",
        "scammerContact": "詐騙者的聯絡方式（如果有）",
        "contactDescription": "任何詐騙者的聯絡方式。",
        "enterScammerContact": "輸入詐騙者的聯絡方式",
        "addContact": "添加聯絡方式",

        // Step3.js 的翻譯
        "provideEvidence": "提供證據",
        "descriptionPlaceholder": "請盡可能詳細地描述案件以支持調查。如果您公開發布，請確保不要在此處輸入任何個人信息。",
        "enterDescription": "輸入案件描述",
        "uploadEvidence": "上傳證據(最多10個)",
        "addFile": "添加檔案",

        // Step4.js 的翻譯
        "pleaseConfirmInfo": "請確認以下資訊，並填寫您的個人資料。",
        "reviewYourInformation": "查看您的資訊",
        "personalInformation": "個人資料",
        "personalInfoDescription": "請填寫以下個人資料，以便我們與您聯繫。",
        "fullName": "全名",
        "enterFullName": "輸入您的全名",
        "email": "電子郵件",
        "enterEmail": "輸入您的電子郵件",
        "phoneNumber": "電話號碼",
        "enterPhoneNumber": "輸入您的電話號碼",
        "country": "國家",
        "enterCountry": "輸入您的國家",
        "city": "城市",
        "enterCity": "輸入您的城市",
        "uploadedFiles": "上傳的文件",
        "scammerAddresses": "詐騙者地址",
        "victimWalletAddress_step4": "受害者的錢包地址",
        "scammerWalletAddress_step4": "詐騙者的錢包地址",
        "chain": "區塊鏈",
        "date": "日期",
        "websites": "網站",
        "tokenIDs": "代幣ID",
        "ipAddresses": "IP地址",
        "contacts": "聯絡方式",
        "noFile": "未上傳文件",
        "transactionScreenshots": "交易截圖",


        // ReportSteps 共用的翻譯
        "next": "下一步",
        "previous": "上一步",
        "confirmAndSubmit": "確認並提交",
        "submitReport": "提交報告",
        "reportSubmitted": "報告提交成功",
        "incidentType": "事件類型",
        "exchange": "交易所",
        "pleaseCompleteFields": "請完成所有必填欄位",
        "selectedFile": "選擇的文件",
        "remove": "移除文件",
        "guestIdNote": "請記錄您的訪客標識符，您可以稍後登入並關聯此報案: ",

      // Profile.js 的翻譯
      "loading": "載入中...",
      "profileInfo": "會員資訊",
      "name": "姓名",
      "save": "保存",
      "enterValidPhoneNumber": "請輸入有效的手機號碼",
      "updateSuccess": "更新成功",
      "updateFailed": "更新失敗，請稍後再試",

      // MyReports.js 的翻譯
      "myReports": "我的報案",
      "reportId": "報案編號",
      "exportAsPDF": "匯出為 PDF",
      "exportAsJSON": "匯出為 JSON",
      "addToFavorites": "加入收藏",
      "addedToFavorites": "已加入收藏",
      "linkGuestReport": "關聯訪客報案",
      "enterGuestId": "請輸入訪客標識符",
      "linkReport": "關聯報案",
      "reportLinked": "報案已成功關聯到您的帳戶",
      "errorLinkingReport": "無法關聯報案，請檢查您的訪客標識符",
      "errorFetchingReports": "取得報案資料失敗",
      "errorAddingToFavorites": "加入收藏失敗"


    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'zh', // 預設語言
  fallbackLng: 'en',  // 語言無法找到時使用
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
